import React, { useState } from 'react';
import { Container, Row, Col, Card, CardBody, Alert, Button } from 'reactstrap';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { Link, withRouter } from 'react-router-dom';
import { forgetUser } from '../../store/actions';
import logo from '../../assets/images/Mimo_Logo.png';
import { connect } from 'react-redux';
import axios from 'axios';

const ForgetPassword = (props) => {
  const [email, setEmail] = useState('');
  const [success, setSuccess] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = () => {
    const { REACT_APP_URL } = process.env;

    let formData = new FormData();
    formData.append('email', email);

    axios
      .post(`${REACT_APP_URL}/api/auth/password/email`, formData, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
      .then((res) => {
        setSuccess(res.data.success.message);
      })
      .catch((error) => {
        setError(
          error.response.data.error
            ? error.response.data.error.message
            : error.response.data.message
        );
      });
  };

  const handleChange = (e) => {
    const { value } = e.target;
    setEmail(value);
  };

  return (
    <>
      <Container className='account-pages my-5 pt-sm-5'>
        <Row className='justify-content-center'>
          <Col md='8' lg='6' xl='5'>
            <Card className='overflow-hidden'>
              <CardBody className='pt-0'>
                <h3 className='text-center mt-4'>
                  <Link to='/' className='logo logo-admin'>
                    <img src={logo} height='80' alt='logo' />
                  </Link>
                </h3>
                <div className='p-3'>
                  <h4 className='text-muted font-size-18 mb-3 text-center'>
                    Reset Password
                  </h4>
                  {success ? <Alert color='success'>{success}</Alert> : ''}

                  {error ? <Alert color='danger'>{error}</Alert> : ''}

                  {props.loginError && (
                    <Alert color='danger'>{props.loginError}</Alert>
                  )}

                  <AvForm
                    className='form-horizontal mt-4'
                    onValidSubmit={handleSubmit}
                  >
                    <div className='form-group'>
                      <label>Email</label>
                      <AvField
                        onChange={handleChange}
                        name='email'
                        type='email'
                        id='email'
                        value={email}
                        placeholder='Enter Email'
                        validate={{
                          required: {
                            value: true,
                            errorMessage: 'Please enter an email',
                          },
                          pattern: {
                            value: '[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$',
                            errorMessage:
                              'You have entered an invalid email address!',
                          },
                        }}
                      />
                    </div>

                    <div className='form-group row'>
                      <div className='col-12 text-right'>
                        {props.loading ? (
                          <Button
                            color='primary'
                            className='w-md waves-effect waves-light'
                          >
                            Loading ...
                          </Button>
                        ) : (
                          <Button
                            color='primary'
                            className='w-md waves-effect waves-light'
                            type='submit'
                          >
                            Reset Password
                          </Button>
                        )}
                      </div>
                    </div>
                  </AvForm>
                </div>
              </CardBody>
            </Card>
            <div className='mt-5 text-center'>
              <p>
                Remember It ?{' '}
                <Link to='/login' className='text-primary'>
                  Sign In Here
                </Link>
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

const mapStatetoProps = (state) => {
  const { message, loginError, loading } = state.Forget;
  return { message, loginError, loading };
};

export default withRouter(
  connect(mapStatetoProps, { forgetUser })(ForgetPassword)
);
