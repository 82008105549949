import React, { useState, useEffect } from 'react';
import { getCurrentUser } from '../../services/authService';
import { setBreadcrumbItems } from '../../store/actions';
import MServiceVersions from './MserviceVersion';
import AndroidVersions from './AndroidVersions';
import AutoDownloads from './AutoDownloads';
import DevicesStatus from './DeviceStatus';
import DeviceModels from './DeviceModels';
import { connect } from 'react-redux';
import { Row, Col } from 'reactstrap';
import MiniCard from './MiniCard';
import {
  getResellerDashboard,
  getSuperAdminDashboard,
  getUserDashboard,
} from './redux/DashboardApi';

const Dashboard = (props) => {
  const [breadcrumbItems] = useState([
    { title: 'Mimo', link: '/' },
    { title: 'Dashboard', link: '#' },
  ]);
  const [dashboardData, setDashboardData] = useState([]);

  useEffect(() => {
    (async () => {
      props.setBreadcrumbItems('Dashboard', breadcrumbItems, true);

      if (getCurrentUser().role_id === 3) {
        try {
          const { data: dashboardData } = await getSuperAdminDashboard();

          setDashboardData(dashboardData);
        } catch (error) {
          console.error(error);
        }
      } else if (getCurrentUser().role_id === 1) {
        try {
          const { data: dashboardData } = await getResellerDashboard();

          setDashboardData(dashboardData);
        } catch (error) {
          console.error(error);
        }
      } else {
        localStorage.setItem('mytime', Date.now());

        try {
          const { data: dashboardData } = await getUserDashboard(
            getCurrentUser().customer_id
          );

          setDashboardData(dashboardData);
        } catch (error) {
          console.error(error);
        }
      }

      props.setBreadcrumbItems('Dashboard', breadcrumbItems, false);
    })();
  }, []);

  return (
    <>
      <Row>
        <MiniCard reports={dashboardData} />
      </Row>

      <Row>
        <Col xl='3'>
          <DevicesStatus reports={dashboardData} />
        </Col>

        <Col xl='6'>
          <DeviceModels reports={dashboardData} />
        </Col>

        <Col xl='3'>
          <AndroidVersions reports={dashboardData} />
        </Col>

        <Col xl='6'>
          <MServiceVersions reports={dashboardData} />
        </Col>

        {getCurrentUser().role_id == 1 ? (
          <Col xl='6'>
            <AutoDownloads reports={dashboardData} />
          </Col>
        ) : (
          ''
        )}
      </Row>
    </>
  );
};

const mapStatetoProps = (state) => {
  const { user } = state.Login;
  return { user };
};

export default connect(mapStatetoProps, { setBreadcrumbItems })(Dashboard);
