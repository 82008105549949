import React, { Component } from 'react';
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Alert,
  Button,
  UncontrolledTooltip,
} from 'reactstrap';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import logo from '../../assets/images/Mimo_Logo.png';
import { forgetUser } from '../../store/actions';
import axios from 'axios';
import SweetAlert from 'react-bootstrap-sweetalert';
import { AvForm, AvField } from 'availity-reactstrap-validation';

class resetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      expire: null,
      signature: null,
      password: null,
      token: null,
      password_confirmation: null,
      msg: null,
      successAlert: false,
      errorAlert: false,
      description: '',
    };
  }

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  handleSubmit = () => {
    const { REACT_APP_URL } = process.env;

    if (this.state.password !== this.state.password_confirmation) {
      this.setState({
        msg: 'Password does not match',
      });
    } else {
      let formData = new FormData();
      formData.append('email', this.state.username);
      formData.append('token', this.state.token);
      formData.append('password', this.state.password);
      formData.append('password_confirmation', this.state.password);

      axios
        .post(`${REACT_APP_URL}/api/auth/password/reset`, formData, {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        })
        .then((res) => {
          this.setState({
            successAlert: true,
            description: res.data.success.message,
          });
        })
        .catch((error) => {
          this.setState({
            errorAlert: true,
            description: 'You entered invalid email',
          });
        });
    }
  };

  componentDidMount() {
    var token = new URLSearchParams(this.props.location.search).get('token');
    this.setState({
      token: token,
    });
  }

  render() {
    return (
      <React.Fragment>
        {this.state.successAlert ? (
          <SweetAlert
            title='Success'
            success
            onConfirm={() => {
              this.setState({
                successAlert: false,
              });
              this.props.history.replace('/login');
            }}
          >
            {this.state.description}
          </SweetAlert>
        ) : null}
        {this.state.errorAlert ? (
          <SweetAlert
            title='Failed'
            danger
            onConfirm={() => {
              this.setState({
                errorAlert: false,
              });
            }}
          >
            {this.state.description}
          </SweetAlert>
        ) : null}

        {this.state.token ? (
          <div className='account-pages my-5 pt-sm-5'>
            <Container>
              <Row className='justify-content-center'>
                <Col md='8' lg='6' xl='5'>
                  <Card className='overflow-hidden'>
                    <CardBody className='pt-0'>
                      <h3 className='text-center mt-4'>
                        <Link to='/' className='logo logo-admin'>
                          <img src={logo} height='80' alt='logo' />
                        </Link>
                      </h3>
                      <div className='p-3'>
                        <h4 className='text-muted font-size-18 mb-3 text-center'>
                          Password Reset
                        </h4>

                        {this.state.msg !== null ? (
                          <Alert color='danger'>{this.state.msg}</Alert>
                        ) : (
                          <Alert color='info'>Enter your Information!</Alert>
                        )}

                        {this.props.loginError && (
                          <Alert color='danger'>{this.props.loginError}</Alert>
                        )}

                        <AvForm
                          className='form-horizontal mt-4'
                          onValidSubmit={this.handleSubmit}
                        >
                          <label>Email</label>
                          <AvField
                            name='username'
                            onChange={this.handleChange}
                            type='email'
                            id='useremail'
                            value={this.state.username}
                            placeholder='Enter Email'
                            validate={{ email: true, required: true }}
                          />

                          <label htmlFor='password'>Password</label>
                          <AvField
                            onChange={this.handleChange}
                            id='password'
                            name='password'
                            type='password'
                            autoComplete='new-password'
                            value={this.state.password}
                            placeholder='Enter password'
                            errorMessage='Please enter a password'
                            validate={{
                              required: { value: true },
                              pattern: {
                                value:
                                  '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})',
                                errorMessage:
                                  "The password you entered doesn't meet the minimum security requirements.",
                              },
                            }}
                          />
                          <UncontrolledTooltip
                            placement='top'
                            target='password'
                          >
                            <li className='text-info h6'>
                              The string must contain at least 1 lowercase
                              alphabetical character.
                            </li>
                            <li className='text-info h6'>
                              The string must contain at least 1 uppercase
                              alphabetical character.
                            </li>
                            <li className='text-info h6'>
                              The string must contain at least 1 numeric
                              character.
                            </li>
                            <li className='text-info h6'>
                              The string must contain at least one special
                              character.
                            </li>
                            <li className='text-info h6'>
                              The string must be eight characters or longer.
                            </li>
                          </UncontrolledTooltip>

                          <label htmlFor='password_confirmation'>
                            Confirm Password
                          </label>
                          <AvField
                            type='password'
                            autoComplete='new-password'
                            onChange={this.handleChange}
                            className='form-control'
                            name='password_confirmation'
                            placeholder='Enter confirm password'
                            errorMessage='Please enter a confirm password'
                            validate={{
                              required: { value: true },
                            }}
                          />

                          <div className='form-group row'>
                            <div className='col-12 text-right'>
                              {this.props.loading ? (
                                <Button
                                  color='primary'
                                  className='w-md waves-effect waves-light'
                                >
                                  Loading ...
                                </Button>
                              ) : (
                                <Button
                                  color='primary'
                                  className='w-md waves-effect waves-light'
                                  type='submit'
                                >
                                  Submit
                                </Button>
                              )}
                            </div>
                          </div>
                        </AvForm>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
        ) : (
          ''
        )}
      </React.Fragment>
    );
  }
}

const mapStatetoProps = (state) => {
  const { message, loginError, loading } = state.Forget;
  return { message, loginError, loading };
};

export default withRouter(
  connect(mapStatetoProps, { forgetUser })(resetPassword)
);
