import React, { Component } from 'react';
import { SelectCustomer } from '../../component/Common/SelectCustomer';
import { SelectReseller } from '../../component/Common/SelectReseller';
import ModalLayout from '../../component/Common/ModalLayout';
import { getCurrentUser } from '../../services/authService';
import { Permissions } from '../../services/permissions';
import { setBreadcrumbItems } from '../../store/actions';
import { AvForm } from 'availity-reactstrap-validation';
import { Col, Row, Card, CardBody } from 'reactstrap';
import secureLocalStorage from 'react-secure-storage';
import SweetAlert from 'react-bootstrap-sweetalert';
import { commandsGroup } from './deviceForm';
import ShowResponse from './ShowResponse';
import { MDBDataTable } from 'mdbreact';
import { connect } from 'react-redux';
import { Button, Label } from 'reactstrap';
import { CSVLink } from 'react-csv';
import Select from 'react-select';
import db from '../../firebase';
import './datatables.scss';
import axios from 'axios';

class response extends Component {
  constructor(props) {
    super(props);
    this.ref = React.createRef();
    this.state = {
      user: JSON.parse(secureLocalStorage.getItem('user')),
      data: [],
      submitedData: [],
      id: [],
      deviceDetails: [],
      confirm_both: false,
      success_dlg: false,
      error_dlg: false,
      dynamic_title: '',
      dynamic_description: '',
      modal_data: {},
      modal_center: false,
      updateResponse: false,
      filteredData: [],

      resellerId: 'all',
      customerId: 'all',

      selectedCommand: null,
      filterCommand: 'all',
      highLightId: null,
      activePage: 0,
      pageCount: 1,

      existTimestamp: [],

      modalShow: false,
      responseData: {},
      unsubscribeFunction: null,
    };
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.state.unsubscribeFunction();
    this.setState = (state, callback) => {
      return;
    };
  }

  handleDownloadCSV = () => {
    this.csvLink.link.click();
  };

  componentDidMount() {
    const { REACT_APP_URL } = process.env;
    let data = secureLocalStorage.getItem('auth');
    let token = data.replace(/['"]+/g, '');
    axios
      .get(
        this.state.user.role_id == 2
          ? `${REACT_APP_URL}/api/auth/device/${this.state.user.customer_id}`
          : `${REACT_APP_URL}/api/auth/device/all`,
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        },
        data
      )
      .then((res) => {
        this.setState({ deviceDetails: res.data }, () => {
          this.fetchResponse();
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }

  fetchResponse = async () => {
    let unsubscribe;
    if (this.state.user.role_id == 2) {
      unsubscribe = db
        .collection('response')
        .orderBy('timestamp', 'desc')
        .where('timestamp', '>', JSON.parse(localStorage.getItem('mytime')))
        .onSnapshot((doc) => {
          doc.docs.map((item) => {
            let firebaseData = item.data();
            var convertDate = new Date(firebaseData.timestamp).toLocaleString();

            const existResponse = this.state.existTimestamp.find(
              (e) => e === item.data().timestamp
            );

            if (!existResponse) {
              const device = this.state.deviceDetails.find(
                ({ mac_address }) => mac_address === item.data().macAddress
              );
              if (device) {
                firebaseData = {
                  ...firebaseData,
                  resellerId: device.resellerId,
                  customerId: device.customer_id,
                  deviceName: device.device_name,
                  timestamp: convertDate,
                };
                this.setState({
                  data: [firebaseData, ...this.state.data],
                  filteredData: [firebaseData, ...this.state.data],
                  existTimestamp: [
                    ...this.state.existTimestamp,
                    item.data().timestamp,
                  ],
                });
              }
            }
          });
          this.setState({
            unsubscribeFunction: unsubscribe,
          });
          this.call(true);
        });
    }

    if (this.state.user.role_id == 1) {
      unsubscribe = db
        .collection('response')
        .orderBy('timestamp', 'desc')
        .where('timestamp', '>', Date.now() - 1 * 24 * 60 * 60 * 1000)
        .onSnapshot((doc) => {
          doc.docs.map((item) => {
            let firebaseData = item.data();
            var convertDate = new Date(firebaseData.timestamp).toLocaleString();

            const existResponse = this.state.existTimestamp.find(
              (e) => e === item.data().timestamp
            );

            if (!existResponse) {
              const device = this.state.deviceDetails.find(
                ({ mac_address }) => mac_address === item.data().macAddress
              );
              if (device) {
                firebaseData = {
                  ...firebaseData,
                  resellerId: device.resellerId,
                  customerId: device.customer_id,
                  deviceName: device.device_name,
                  timestamp: convertDate,
                };
                this.setState({
                  data: [firebaseData, ...this.state.data],
                  filteredData: [firebaseData, ...this.state.data],
                  existTimestamp: [
                    ...this.state.existTimestamp,
                    item.data().timestamp,
                  ],
                });
              }
            }
          });
          this.setState({
            unsubscribeFunction: unsubscribe,
          });
          this.call(true);
        });
    }

    if (this.state.user.role_id == 3) {
      unsubscribe = db
        .collection('response')
        .orderBy('timestamp', 'desc')
        .where('timestamp', '>', Date.now() - 1 * 24 * 60 * 60 * 1000)
        .onSnapshot((doc) => {
          doc.docs.map((item) => {
            let firebaseData = item.data();
            var convertDate = new Date(firebaseData.timestamp).toLocaleString();

            const existResponse = this.state.existTimestamp.find(
              (e) => e === item.data().timestamp
            );

            if (!existResponse) {
              const device = this.state.deviceDetails.find(
                ({ mac_address }) => mac_address === item.data().macAddress
              );
              firebaseData = {
                ...firebaseData,
                resellerId: device ? device.resellerId : '',
                customerId: device ? device.customer_id : '',
                deviceName: device ? device.device_name : '',
                timestamp: convertDate,
              };
              this.setState({
                data: [firebaseData, ...this.state.data],
                filteredData: [firebaseData, ...this.state.data],
                existTimestamp: [
                  ...this.state.existTimestamp,
                  item.data().timestamp,
                ],
              });
            }
          });
          this.setState({
            unsubscribeFunction: unsubscribe,
          });
          this.call(true);
        });
    }
  };

  call(e) {
    this.props.parentCallback(e);
  }

  handleClear = () => {
    const { REACT_APP_URL } = process.env;
    let data = secureLocalStorage.getItem('auth');
    let token = data.replace(/['"]+/g, '');

    this.setState(
      {
        error: true,
        submitedData: {
          command: this.state.filterCommand,
          id:
            this.state.user.role_id === 1 || this.state.user.role_id === 3
              ? this.state.customerId
              : this.state.user.customer_id,
        },
      },
      () => {
        axios
          .post(
            `${REACT_APP_URL}/api/auth/deleteFireResponse`,
            this.state.submitedData,
            {
              headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then(() => {
            this.setState({
              success_dlg: true,
              dynamic_title: 'Success',
              dynamic_description: 'Response has been cleared.',
              error: false,
              highLightId: null,
              data:
                this.state.customerId === 'all'
                  ? []
                  : this.state.data.filter(
                      (item) => item.customerId != this.state.customerId
                    ),
              filteredData:
                this.state.customerId === 'all'
                  ? []
                  : this.state.filteredData.filter(
                      (item) => item.customerId != this.state.customerId
                    ),
              existTimestamp:
                this.state.customerId === 'all'
                  ? []
                  : this.state.existTimestamp.filter(
                      (item) => item.customerId != this.state.customerId
                    ),
              selectedCommand: null,
              filterCommand: 'all',
            });
          })
          .catch((error) => {
            console.error(error);
          });
      }
    );
  };

  changePage = (activePage) => {
    if (activePage - 1 != this.ref.current.state.activePage) {
      if (this.state.highLightId) {
        let column = document.getElementById(this.state.highLightId);
        let row = column.parentElement.parentElement;
        row.classList.remove('highLight');
      }
      this.setState(
        {
          highLightId: null,
          activePage: activePage - 1,
          pageCount: this.ref.current.state.entries,
        },
        () => {
          this.ref.current.changeActivePage(this.state.activePage);
        }
      );
    }
  };

  handleFirstClick = (key, page) => {
    let column = document.getElementById(key);
    let row = column.parentElement.parentElement;
    row.classList.add('highLight');
    this.setState({ highLightId: key - page * this.state.pageCount });
  };
  handleRowClicked = (key) => {
    if (key > this.state.pageCount && this.state.pageCount !== 1) {
      this.handleFirstClick(key, this.ref.current.state.activePage);
    } else {
      if (this.state.highLightId != null && this.state.highLightId != key) {
        let column = document.getElementById(this.state.highLightId);
        let row = column.parentElement.parentElement;
        row.classList.remove('highLight');
      }
      let column = document.getElementById(key);
      let row = column.parentElement.parentElement;
      row.classList.add('highLight');
      this.setState({ highLightId: key });
    }
  };

  toggleModal = () => {
    this.setState({
      modalShow: !this.state.modalShow,
    });
  };

  // Filters
  handleReseller = (id) => {
    this.setState({
      resellerId: id === 'all' ? 'all' : parseInt(id),
      customerId: 'all',
      selectedCommand: 'all',
      filterCommand: 'all',
      filteredData: this.state.data.filter((item) =>
        id === 'all' ? item : item.resellerId == id
      ),
    });
  };

  handleCustomer = (id) => {
    if (this.state.highLightId) {
      let column = document.getElementById(this.state.highLightId);
      let row = column.parentElement.parentElement;
      row.classList.remove('highLight');
    }

    this.setState(
      {
        customerId: id === 'all' ? 'all' : parseInt(id),
        filteredData: this.state.data
          .filter((item) =>
            this.state.resellerId === 'all'
              ? item
              : item.resellerId === this.state.resellerId
          )
          .filter((item) => (id === 'all' ? item : item.customerId == id)),
        highLightId: null,
        selectedCommand: 'all',
        filterCommand: 'all',
      },
      () => {
        this.ref.current.changeActivePage(0);
      }
    );
  };

  handleSelectCommand = (selectedCommand) => {
    if (this.state.highLightId) {
      let column = document.getElementById(this.state.highLightId);
      let row = column.parentElement.parentElement;
      row.classList.remove('highLight');
    }
    this.setState(
      {
        selectedCommand,
        filterCommand: selectedCommand.value,
        highLightId: null,
        filteredData: this.state.data
          .filter((item) =>
            this.state.resellerId === 'all'
              ? item
              : item.resellerId === this.state.resellerId
          )
          .filter((item) =>
            this.state.customerId === 'all'
              ? item
              : item.customerId === this.state.customerId
          )
          .filter((item) =>
            selectedCommand.value === 'all'
              ? item
              : item.command === selectedCommand.value
          ),
      },
      () => {
        this.ref.current.changeActivePage(0);
      }
    );
  };

  getCSVData = (deviceData) => {
    const csvData = [];
    csvData.push([
      'User',
      'Timestamp',
      'Command Name',
      'Mac Address',
      'Status',
      'Message',
    ]);
    deviceData.forEach((deviceItem) => {
      csvData.push([
        deviceItem.user ? deviceItem.user : 'NA',
        deviceItem.timestamp ? deviceItem.timestamp : 'NA',
        deviceItem.command ? deviceItem.command : 'NA',
        deviceItem.macAddress ? deviceItem.macAddress : 'NA',
        deviceItem.Status ? deviceItem.Status : 'NA',
        deviceItem.Message ? deviceItem.Message : 'NA',
      ]);
    });
    return csvData;
  };

  render() {
    const { filteredData } = this.state;
    const data = {
      columns: [
        {
          label: 'User',
          field: 'user',
          sort: 'desc',
        },
        {
          label: 'Device Name',
          field: 'deviceName',
          sort: 'desc',
        },
        {
          label: 'Command Name',
          field: 'command',
          sort: 'disabled',
        },
        {
          label: 'Timestamp',
          field: 'timestamp',
          sort: 'asc',
        },
        {
          label: 'Data',
          field: 'jsonData',
          sort: 'disabled',
        },
      ],
      rows: this.state.filteredData
        .sort((a, b) => {
          return new Date(b.timestamp) - new Date(a.timestamp);
        })
        .map((obj, key) => ({
          ...obj,
          user: obj.user,
          deviceName: obj.deviceName,
          command: obj.command,
          timestamp: obj.timestamp,
          jsonData: (
            <>
              <i
                className='mr-1 mdi mdi-file-eye-outline iconHover'
                style={{ color: '#408EBA', fontSize: '23px' }}
                role='button'
                id={key + 1}
                onClick={() => {
                  this.setState(
                    {
                      responseData: obj,
                    },
                    () => {
                      this.toggleModal();
                      this.handleRowClicked(key + 1);
                    }
                  );
                }}
              ></i>

              {obj['Bug Report URL'] ||
              obj['Screenshot URL'] ||
              obj['MLock Settings URL'] ? (
                <i
                  className='mr-1 mdi mdi-link-variant iconHover'
                  style={{ color: '#9EC0F2', fontSize: '23px' }}
                  role='button'
                  onClick={() =>
                    window.open(
                      obj['Bug Report URL']
                        ? obj['Bug Report URL']
                        : obj['Screenshot URL']
                        ? obj['Screenshot URL']
                        : obj['MLock Settings URL'],
                      '_blank'
                    )
                  }
                ></i>
              ) : (
                ''
              )}
            </>
          ),
        })),
    };
    return (
      <>
        {this.state.success_dlg ? (
          <SweetAlert
            success
            title={this.state.dynamic_title}
            onConfirm={() => this.setState({ success_dlg: false })}
          >
            {this.state.dynamic_description}
          </SweetAlert>
        ) : null}
        {this.state.success_confirm ? (
          <SweetAlert
            success
            title={this.state.dynamic_title}
            confirmBtnBsStyle='success'
            cancelBtnBsStyle='danger'
            onConfirm={() =>
              this.setState({ success_confirm: false, alert_confirm: false })
            }
          >
            {this.state.dynamic_description}
          </SweetAlert>
        ) : null}
        {this.state.error_dlg ? (
          <SweetAlert
            error
            title={this.state.dynamic_title}
            onConfirm={() => this.setState({ error_dlg: false })}
          >
            {this.state.dynamic_description}
          </SweetAlert>
        ) : null}
        {this.state.success_dlg1 ? (
          <SweetAlert
            title='Good job!'
            success
            showCancel
            confirmBtnBsStyle='success'
            cancelBtnBsStyle='danger'
            onConfirm={() =>
              this.setState({ success_dlg1: false, alert_confirm: false })
            }
          >
            You clicked the button!
          </SweetAlert>
        ) : null}
        {this.state.confirm_both ? (
          <SweetAlert
            title='Are you sure?'
            warning
            showCancel
            confirmBtnBsStyle='success'
            cancelBtnBsStyle='danger'
            onConfirm={(e) => {
              this.handleDelete(e, this.deleted_data);
            }}
            onCancel={() =>
              this.setState({
                confirm_both: false,
                error_dlg: true,
                dynamic_title: 'Cancelled',
                deleted_data: {},
                dynamic_description: 'Your data is safe :)',
              })
            }
          >
            You won't be able to revert this!
          </SweetAlert>
        ) : null}

        <Card style={{ overflowY: 'scroll', maxHeight: '680px' }}>
          <CardBody>
            <Col xs='12'>
              <Row>
                <Col xs='6'>
                  <h5>Response History ({this.state.filteredData.length})</h5>
                </Col>

                {Permissions().clearResponsePermi ? (
                  <Col xs='6' className='flex-end-xs'>
                    {this.state.error == true ? (
                      <img
                        className='float-right'
                        src='1.gif'
                        width='30px'
                        height='30px'
                        style={{ position: 'absolute', right: 210 }}
                      ></img>
                    ) : (
                      ''
                    )}
                    <Button
                      type='submit'
                      color='primary'
                      onClick={() => this.handleDownloadCSV()}
                      className='waves-effect waves-light p-2 flex-check'
                      id='sa-title'
                      style={{ marginRight: '5px' }}
                      disabled={filteredData.length === 0}
                    >
                      Export CSV
                    </Button>
                    <CSVLink
                      data={this.getCSVData(filteredData)}
                      filename={'Device_Task_Logs.csv'}
                      ref={(csvRef) => (this.csvLink = csvRef)}
                    />
                    <Button
                      type='submit'
                      color='primary'
                      onClick={() => this.handleClear()}
                      className='waves-effect waves-light mr-n3 p-2 float-right flex-check'
                      id='sa-title'
                    >
                      Clear Response
                    </Button>
                  </Col>
                ) : (
                  ''
                )}
              </Row>
            </Col>
          </CardBody>

          <CardBody>
            <Col xs='12'>
              <Row>
                {getCurrentUser().role_id === 3 ? (
                  <Col xs='4'>
                    <AvForm>
                      <SelectReseller
                        className=''
                        fieldError={null}
                        resellerId={this.state.resellerId}
                        handleReseller={this.handleReseller}
                      />
                    </AvForm>
                  </Col>
                ) : (
                  ''
                )}

                {getCurrentUser().role_id !== 2 ? (
                  <Col xs='4'>
                    <AvForm>
                      <SelectCustomer
                        className=''
                        fieldError={null}
                        resellerId={this.state.resellerId}
                        customerId={this.state.customerId}
                        handleCustomer={this.handleCustomer}
                      />
                    </AvForm>
                  </Col>
                ) : (
                  ''
                )}

                <Col xs='4'>
                  <Label>Select Command</Label>
                  <Select
                    value={this.state.selectedCommand}
                    options={[
                      {
                        label: 'All Commands',
                        options: [{ label: 'All', value: 'all' }],
                      },
                      ...commandsGroup,
                    ]}
                    autoComplete='nope'
                    name='command'
                    onChange={(e) => {
                      this.handleSelectCommand(e);
                    }}
                  />
                </Col>
              </Row>
            </Col>
            <br></br>
            <br></br>

            <MDBDataTable
              responsive
              bordered
              striped
              data={data}
              ref={this.ref}
              entries={10}
              onPageChange={({ activePage }) => {
                this.changePage(activePage);
              }}
            />
          </CardBody>
        </Card>

        {this.state.modalShow ? (
          <ModalLayout
            toggleModal={this.toggleModal}
            isOpen={this.state.modalShow}
            title='Response Data'
            size='xl'
          >
            <ShowResponse responseData={this.state.responseData} />
          </ModalLayout>
        ) : (
          ''
        )}
      </>
    );
  }
}

export default connect(null, { setBreadcrumbItems })(response);
