import React, { useState, useEffect } from "react";
import { createProfile, getApkUrl, getUpdateApkData } from "./redux/profileApi";
import { SelectReseller } from "../../component/Common/SelectReseller";
import { SelectCustomer } from "../../component/Common/SelectCustomer";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { FormGroup, Label, Button, Table } from "reactstrap";
import { getCurrentUser } from "../../services/authService";
import { mimoLoader } from "../../store/actions";
import { useDispatch } from "react-redux";
import Select from "react-select";
import Switch from "react-switch";

const CreateProfile = ({
  toggleModal,
  setSuccessAlert,
  setErrorAlert,
  setDescription,
}) => {
  const dispatch = useDispatch();
  const [resellerId, setResellerId] = useState(
    getCurrentUser().role_id !== 3 ? getCurrentUser().reseller_id : "all"
  );
  const [profileData, setProfileData] = useState({
    customer:
      getCurrentUser().role_id === 2 ? getCurrentUser().customer_id : "all",
  });
  const [dName, setDName] = useState([
    { profile_type: "Wifi" },
    { profile_type: "Device Setting" },
    { profile_type: "Ethernet Configs" },
    { profile_type: "MLock Configurations" },
    { profile_type: "Install User App" },
    // { profile_type: "updateMIMOService" },
    { profile_type: "assignSSIDProfile" },
  ]);
  const [updateApkData, setUpdateApkData] = useState([]);
  const [selectedMulti, setSelectedMulti] = useState([]);
  const [passwordEyeIcon, setPasswordEyeIcon] = useState(true);
  const [passwordInputType, setPasswordInputType] = useState("password");
  const [mState, setMState] = useState(false);
  const [sSetting, setSSetting] = useState(false);
  const [hSetting, setHSetting] = useState(false);
  const [optionGroup, setOptionGroup] = useState([]);
  const [resellerError, setResellerError] = useState(null);
  const [customerError, setCustomerError] = useState(null);
  const [profileError, setProfileError] = useState(null);
  const [ipModeError, setIpModeError] = useState(null);
  const [modeError, setModeError] = useState(null);
  const [error, setError] = useState('');

  useEffect(() => {
    (async () => {
      dispatch(mimoLoader(true));
      try {
        const { data: updateApkData } = await getUpdateApkData("MService");
        const { data: apkUrlData } = await getApkUrl(profileData.customer);

        setUpdateApkData(updateApkData);
        setOptionGroup(
          apkUrlData.map(({ url }) => ({
            options: [
              {
                label: url,
                value: `${process.env.REACT_APP_URL}/${url}`,
              },
            ],
          }))
        );
      } catch (error) {
        console.error(error);
      }
      dispatch(mimoLoader(false));
    })();
  }, []);

  const handleMulti = (selectedMulti) => {
    if (selectedMulti) {
      setSelectedMulti(selectedMulti);
      setProfileData((prevState) => ({
        ...prevState,
        apk_url: selectedMulti.map((data) => data.value),
      }));
    } else {
      setSelectedMulti([]);
      setProfileData((prevState) => ({
        ...prevState,
        apk_url: [],
      }));
    }
  };

  const handleReseller = (id) => {
    setProfileData((prevState) => ({
      ...prevState,
      customer: "all",
    }));
    setResellerId(id === "all" ? "all" : parseInt(id));
    setResellerError(id === "all" ? "Please select a reseller" : null);
  };

  const handleCustomer = async (id) => {
    setProfileData((prevState) => ({
      ...prevState,
      customer: id === "all" ? "all" : parseInt(id),
    }));
    setResellerError(id === "all" ? "Please select a customer" : null);

    dispatch(mimoLoader(true));
    try {
      const { data: apkUrlData } = await getApkUrl(id);
      setOptionGroup(
        apkUrlData.map(({ url }) => ({
          options: [
            {
              label: url,
              value: `${process.env.REACT_APP_URL}/${url}`,
            },
          ],
        }))
      );
    } catch (error) {
      console.error(error);
    }
    dispatch(mimoLoader(false));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProfileData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleProfile = async (e) => {
    const { name, value } = e.target;
    if (value === "assignSSIDProfile") {
      setProfileData({
        customer: profileData.customer,
        profile_name: profileData.profile_name,
        [name]: value,
        hidden: false,
        securityType: "None",
        proxyType: "None"
      });
      setProfileError('')
    }
    else {
      setProfileData({
        customer: profileData.customer,
        profile_name: profileData.profile_name,
        [name]: value,
      });
      setProfileError('')
    }
  };

  const handleValidation = () => {
    if (!profileData.profile_type) {
      setProfileError(true);
      return;
    }
    if (profileData.profile_type === "Wifi" ||
      profileData.profile_type === "Ethernet Configs") {
      if (!profileData.ip_mode) {
        setIpModeError(true);
        return;
      }
    }
    if (profileData.profile_type === "MLock Configurations") {
      if (!profileData.mode) {
        setModeError(true);
        return;
      }
    }
    if (profileData.profile_type === "Install User App") {
      if (!profileData.apk_url) {
        setError(true);
        return;
      }
    }
    if (resellerId === "all") {
      setResellerError("Please select a reseller");
      return false;
    } else if (profileData.customer === "all") {
      setCustomerError("Please select a customer");
      return false;
    } else if (
      profileData.profile_type === "assignSSIDProfile" &&
      !profileData.password
    ) {
      setErrorAlert(true);
      setDescription("Please enter the network password");
      return false;
    } else if (profileData.profile_type === "Device Setting") {
      const updatedProfileData = {
        customer: profileData.customer,
        profile_name: profileData.profile_name,
        profile_type: profileData.profile_type,
        brightness: profileData.brightness || "null",
        system_volume: profileData.system_volume || "null",
        media_volume: profileData.media_volume || "null",
        light: profileData.light || "null",
      };
      const submitedData = {
        data: updatedProfileData,
        slug: JSON.stringify(updatedProfileData),
      };
      handleSubmit(submitedData);
    } else {
      const submitedData = {
        data: profileData,
        slug: JSON.stringify(profileData),
      };
      handleSubmit(submitedData);
    }
  };

  const handleSubmit = async (data) => {
    dispatch(mimoLoader(true));
    try {
      const response = await createProfile(data);
      if (response.data.hasOwnProperty("error")) {
        toggleModal();
        setErrorAlert(true);
        setDescription("Profile Name is all ready taken");
      }
      else {
        toggleModal();
        setSuccessAlert(true);
        setDescription("Profile has been successfully created");
      }
    } catch (error) {
      console.error(error);
      setErrorAlert(true);
      setDescription("Profile not created");
    }
    dispatch(mimoLoader(false));
  };

  const handleMode = (e) => {
    const { name, value } = e.target;
    setIpModeError('');
    setModeError('');
    setError('');
    if (
      profileData.profile_type == "MLock Configurations" &&
      value == "video"
    ) {
      setProfileData({
        customer: profileData.customer,
        profile_name: profileData.profile_name,
        profile_type: profileData.profile_type,
        videoRepeatMode: "one",
        contentUpdateAllowed: "true",
        allowGuestUser: "true",
        enableScrollText: "true",
        updateMlockPassword: "",
        [name]: value,
      });
    } else if (
      profileData.profile_type == "MLock Configurations" &&
      value == "web"
    ) {
      setProfileData({
        customer: profileData.customer,
        profile_name: profileData.profile_name,
        profile_type: profileData.profile_type,
        allowGuestUser: "true",
        enableScrollText: "true",
        disableCookies: "false",
        enableAutoRefreshWebpage: "true",
        disableFormData: "true",
        enableAutoRefreshWebsiteAfterFixedInterval: "false",
        autorefreshTimeInMinutes: "15",
        updateMlockPassword: "",
        [name]: value,
      });
    } else if (
      profileData.profile_type == "MLock Configurations" &&
      value == "app"
    ) {
      setProfileData({
        customer: profileData.customer,
        profile_name: profileData.profile_name,
        profile_type: profileData.profile_type,
        allowGuestUser: "true",
        enableScrollText: "true",
        updateMlockPassword: "",
        [name]: value,
      });
    } else {
      setProfileData({
        customer: profileData.customer,
        profile_name: profileData.profile_name,
        profile_type: profileData.profile_type,
        [name]: value,
      });
    }
  };

  // Show Password
  const handleShowPassword = (obj) => {
    setPasswordEyeIcon(obj ? true : false);
    setPasswordInputType(obj ? "password" : "text");
  };

  // Assign SSID Profile
  const Offsymbol = (text) => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2,
        }}
      >
        {" "}
        {text}
      </div>
    );
  };
  const OnSymbol = (text) => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2,
        }}
      >
        {" "}
        {text}
      </div>
    );
  };

  return (
    <>
      <AvForm onValidSubmit={handleValidation}>
        {getCurrentUser().role_id === 3 ? (
          <SelectReseller
            className=""
            fieldError={resellerError}
            resellerId={resellerId}
            handleReseller={handleReseller}
          />
        ) : (
          ""
        )}

        {getCurrentUser().role_id !== 2 ? (
          <SelectCustomer
            className=""
            fieldError={customerError}
            resellerId={resellerId}
            customerId={profileData.customer}
            handleCustomer={handleCustomer}
          />
        ) : (
          ""
        )}

        <AvField
          name="profile_name"
          label="Profile Name"
          placeholder="Type Name"
          type="text"
          value={profileData.profile_name}
          onChange={handleChange}
          errorMessage="Enter  Profile Name"
          validate={{ required: { value: true } }}
        />

        <FormGroup>
          <div>
            <Label>Profile Type</Label>
            <select
              required
              onChange={handleProfile}
              name="profile_type"
              className="custom-select"
            >
              <option defaultValue value="">
                Select Profile Type
              </option>
              {dName.map((item, index) => {
                return (
                  <option key={index} value={item.profile_type}>
                    {item.profile_type == "Wifi" ? "WiFi Settings" : ""}
                    {item.profile_type == "Device Setting"
                      ? "Device Settings"
                      : ""}
                    {item.profile_type == "Ethernet Configs"
                      ? "Ethernet Configs"
                      : ""}
                    {item.profile_type == "MLock Configurations"
                      ? "MLock Configurations"
                      : ""}
                    {item.profile_type == "Install User App"
                      ? "Install User App"
                      : ""}
                    {item.profile_type == "updateMIMOService"
                      ? "Update MService 2020"
                      : ""}
                    {item.profile_type == "assignSSIDProfile"
                      ? "Assign SSID Profile"
                      : ""}
                  </option>
                );
              })}
            </select>
            {profileError && <p style={{ color: 'red' }}>Select Profile Type</p>}
          </div>
        </FormGroup>

        {/* <AvField
                  name="activation_key"
                  label="Activation Key"
                  placeholder="Type Something"
                  type="text"
                  value={profileData.activation_key || ""}
                  onChange={handleChange}
                  errorMessage="Enter Activation Key"
                  validate={{ required: { value: true } }}
                /> */}

        {profileData.profile_type == "Wifi" ||
          profileData.profile_type == "Ethernet Configs" ? (
          <>
            <FormGroup>
              <div>
                <Label>IP Mode</Label>
                <select
                  required
                  onChange={handleMode}
                  name="ip_mode"
                  className="custom-select"
                >
                  <option defaultValue value="">
                    Select IP Mode
                  </option>

                  <option value="Static">Static</option>
                  <option value="DHCP">DHCP</option>
                </select>
                {ipModeError && <p style={{ color: 'red' }}>Select IP Mode</p>}
              </div>
            </FormGroup>

            {profileData.ip_mode == "Static" ? (
              <>
                <AvField
                  name="ip_address"
                  label="IP Address"
                  placeholder="192.168.1.1"
                  type="text"
                  value={profileData.ip_address}
                  onChange={handleChange}
                  errorMessage="Enter IP"
                  validate={{ required: { value: true } }}
                />
                <AvField
                  name="gateway"
                  label="Gateway"
                  placeholder="192.168.1.1"
                  type="text"
                  value={profileData.gateway}
                  onChange={handleChange}
                  errorMessage="Enter Gateway"
                  validate={{ required: { value: true } }}
                />
                <AvField
                  name="dns1"
                  label="DNS1"
                  placeholder="8.8.8.8"
                  type="text"
                  value={profileData.dns1}
                  onChange={handleChange}
                  errorMessage="Enter DNS"
                  validate={{ required: { value: true } }}
                />
                <AvField
                  name="dns2"
                  label="DNS2"
                  placeholder="8.8.8.8"
                  type="text"
                  value={profileData.dns2}
                  onChange={handleChange}
                  errorMessage="Enter DNS"
                  validate={{ required: { value: true } }}
                />
                <AvField
                  name="prefix_length"
                  label="Prefix Length"
                  placeholder="Number between 0 - 25"
                  min={0}
                  onChange={handleChange}
                  value={profileData.prefix_length}
                  type="number"
                  errorMessage="This value should be between 0 and 25."
                  validate={{
                    required: { value: true },
                    min: { value: 0 },
                    max: { value: 25 },
                  }}
                />
              </>
            ) : (
              ""
            )}
          </>
        ) : (
          ""
        )}

        {profileData.profile_type == "Device Setting" ? (
          <>
            <AvField
              name="brightness"
              label="Brightness Value"
              placeholder="Number between 0 - 255"
              onChange={handleChange}
              value={profileData.brightness}
              type="text"
              errorMessage="Select Brightness"
              validate={{ required: { value: true } }}
            />

            <AvField
              name="system_volume"
              label="System Volume"
              placeholder="Number between 0 - 7"
              onChange={handleChange}
              value={profileData.system_volume}
              type="text"
              errorMessage="Enter  System Volume"
              validate={{ required: { value: true } }}
            />

            <AvField
              name="media_volume"
              label="Media Volume"
              placeholder="Number between 0 - 15"
              onChange={handleChange}
              value={profileData.media_volume}
              type="text"
              errorMessage="Enter  Media Volume"
              validate={{ required: { value: true } }}
            />

            <AvField
              name="light"
              label="Enter LED Color"
              placeholder="LED Color in HEX CODE e.g 00hh0"
              type="text"
              value={profileData.light}
              onChange={handleChange}
              errorMessage="This value should be between 0 and 25."
              validate={{ required: { value: true } }}
            />
          </>
        ) : (
          ""
        )}

        {profileData.profile_type == "MLock Configurations" ? (
          <>
            <FormGroup>
              <div>
                <Label>Select Mode</Label>
                <br></br>

                <select
                  style={{
                    marginLeft: "0px",
                    width: "100%",
                    maxHeight: "50%",
                  }}
                  required
                  onChange={handleMode}
                  name="mode"
                  className="custom-select"
                >
                  <option defaultValue value="">
                    Select
                  </option>
                  <option value="video">Video</option>
                  <option value="web">Web</option>
                  <option value="app">App</option>
                </select>
                {modeError && <p style={{ color: 'red' }}>Select Mode</p>}
              </div>
            </FormGroup>

            {profileData.mode == "video" ? (
              <>
                <FormGroup className="mb-0">
                  <div>
                    <Table className="table-centered table-vertical table-nowrap">
                      <tbody>
                        <tr>
                          <td>
                            <label>Auto Start</label>
                          </td>
                          <td>
                            <input
                              onChange={(e) => {
                                if (e.target.checked == true) {
                                  setProfileData((prevState) => ({
                                    ...prevState,
                                    autoStart: "true",
                                  }));
                                } else {
                                  setProfileData((prevState) => ({
                                    ...prevState,
                                    autoStart: "false",
                                  }));
                                }
                              }}
                              name="autoStart"
                              type="checkbox"
                              className="m-0 form-control-sm ml-5"
                            />
                          </td>
                        </tr>

                        <tr>
                          <td>
                            <label>Reboot Device</label>
                          </td>
                          <td>
                            <input
                              onChange={(e) => {
                                if (e.target.checked == true) {
                                  setProfileData((prevState) => ({
                                    ...prevState,
                                    rebootDevice: "true",
                                  }));
                                } else {
                                  setProfileData((prevState) => ({
                                    ...prevState,
                                    rebootDevice: "false",
                                  }));
                                }
                              }}
                              name="rebootDevice"
                              type="checkbox"
                              className="m-0 form-control-sm ml-5"
                            />
                          </td>
                        </tr>

                        <tr>
                          <td>
                            <label>Refresh PlayScreen</label>
                          </td>
                          <td>
                            <input
                              onChange={(e) => {
                                if (e.target.checked == true) {
                                  setProfileData((prevState) => ({
                                    ...prevState,
                                    refreshScreen: "true",
                                  }));
                                } else {
                                  setProfileData((prevState) => ({
                                    ...prevState,
                                    refreshScreen: "false",
                                  }));
                                }
                              }}
                              name="refreshScreen"
                              type="checkbox"
                              className="m-0 form-control-sm ml-5"
                            />
                          </td>
                        </tr>
                      </tbody>
                    </Table>

                    <div className="mb-3">
                      <Label>Loop Videos</Label>
                      <br></br>

                      <select
                        style={{
                          marginLeft: "0px",
                          width: "100%",
                          maxHeight: "50%",
                        }}
                        required
                        onChange={(e) => {
                          setProfileData((prevState) => ({
                            ...prevState,
                            videoRepeatMode: e.target.value,
                          }));
                        }}
                        name="videoRepeatMode"
                        className="custom-select"
                      >
                        <option defaultValue value="one">
                          One
                        </option>
                        <option value="all">All</option>
                      </select>
                    </div>

                    <div className="mb-3">
                      <Label>Enable Content Update</Label>
                      <br></br>

                      <select
                        style={{
                          marginLeft: "0px",
                          width: "100%",
                          maxHeight: "50%",
                        }}
                        required
                        onChange={(e) => {
                          setProfileData((prevState) => ({
                            ...prevState,
                            contentUpdateAllowed: e.target.value,
                          }));
                        }}
                        name="contentUpdateAllowed"
                        className="custom-select"
                      >
                        <option defaultValue value="true">
                          True
                        </option>
                        <option value="false">False</option>
                      </select>
                    </div>

                    <AvField
                      name="videoContentPath"
                      label="Video Path"
                      placeholder="/storage/emulated/"
                      type="text"
                      value={profileData.videoContentPath}
                      onChange={handleChange}
                      errorMessage="Enter Path"
                    />
                  </div>
                </FormGroup>
              </>
            ) : (
              ""
            )}

            {profileData.mode == "web" ? (
              <>
                <FormGroup className="mb-0">
                  <div>
                    <Table className="table-centered table-vertical table-nowrap">
                      <tbody>
                        <tr>
                          <td>
                            <label>Auto Start</label>
                          </td>
                          <td>
                            <input
                              onChange={(e) => {
                                if (e.target.checked == true) {
                                  setProfileData((prevState) => ({
                                    ...prevState,
                                    autoStart: "true",
                                  }));
                                } else {
                                  setProfileData((prevState) => ({
                                    ...prevState,
                                    autoStart: "false",
                                  }));
                                }
                              }}
                              name="autoStart"
                              type="checkbox"
                              className="m-0 form-control-sm ml-5"
                            />
                          </td>
                        </tr>

                        <tr>
                          <td>
                            <label>Reboot Device</label>
                          </td>
                          <td>
                            <input
                              onChange={(e) => {
                                if (e.target.checked == true) {
                                  setProfileData((prevState) => ({
                                    ...prevState,
                                    rebootDevice: "true",
                                  }));
                                } else {
                                  setProfileData((prevState) => ({
                                    ...prevState,
                                    rebootDevice: "false",
                                  }));
                                }
                              }}
                              name="rebootDevice"
                              type="checkbox"
                              className="m-0 form-control-sm ml-5"
                            />
                          </td>
                        </tr>

                        <tr>
                          <td>
                            <label>Refresh PlayScreen</label>
                          </td>
                          <td>
                            <input
                              onChange={(e) => {
                                if (e.target.checked == true) {
                                  setProfileData((prevState) => ({
                                    ...prevState,
                                    refreshScreen: "true",
                                  }));
                                } else {
                                  setProfileData((prevState) => ({
                                    ...prevState,
                                    refreshScreen: "false",
                                  }));
                                }
                              }}
                              name="refreshScreen"
                              type="checkbox"
                              className="m-0 form-control-sm ml-5"
                            />
                          </td>
                        </tr>

                        <tr>
                          <td>
                            <label>Load File</label>
                          </td>
                          <td>
                            <input
                              onChange={(e) => {
                                if (e.target.checked == true) {
                                  setProfileData((prevState) => ({
                                    ...prevState,
                                    loadFileWebview: "true",
                                    websiteURL: "",
                                  }));
                                } else {
                                  setProfileData((prevState) => ({
                                    ...prevState,
                                    loadFileWebview: "false",
                                    loadFileWebviewPathName: "",
                                  }));
                                }
                              }}
                              name="loadFileWebview"
                              type="checkbox"
                              className="m-0 form-control-sm ml-5"
                            />
                          </td>
                        </tr>
                      </tbody>
                    </Table>

                    {profileData.loadFileWebview == "false" ||
                      profileData?.loadFileWebview == null ? (
                      <>
                        <div className="mb-3">
                          <Label>Enable Auto Refresh Website</Label>
                          <br></br>

                          <select
                            style={{
                              marginLeft: "0px",
                              width: "100%",
                              maxHeight: "50%",
                            }}
                            required
                            onChange={(e) => {
                              setProfileData((prevState) => ({
                                ...prevState,
                                enableAutoRefreshWebsiteAfterFixedInterval:
                                  e.target.value,
                              }));
                            }}
                            name="enableAutoRefreshWebsiteAfterFixedInterval"
                            className="custom-select"
                          >
                            <option defaultValue value="false">
                              False
                            </option>
                            <option value="true">True</option>
                          </select>
                        </div>

                        <div className="mb-3">
                          <Label>Disable Cookies</Label>
                          <br></br>

                          <select
                            style={{
                              marginLeft: "0px",
                              width: "100%",
                              maxHeight: "50%",
                            }}
                            required
                            onChange={(e) => {
                              setProfileData((prevState) => ({
                                ...prevState,
                                disableCookies: e.target.value,
                              }));
                            }}
                            name="disableCookies"
                            className="custom-select"
                          >
                            <option defaultValue value="false">
                              False
                            </option>
                            <option value="true">True</option>
                          </select>
                        </div>

                        <div className="mb-3">
                          <Label>Disable Saving Form Data</Label>
                          <br></br>

                          <select
                            style={{
                              marginLeft: "0px",
                              width: "100%",
                              maxHeight: "50%",
                            }}
                            required
                            onChange={(e) => {
                              setProfileData((prevState) => ({
                                ...prevState,
                                disableFormData: e.target.value,
                              }));
                            }}
                            name="disableFormData"
                            className="custom-select"
                          >
                            <option defaultValue value="true">
                              True
                            </option>
                            <option value="false">False</option>
                          </select>
                        </div>

                        <div className="mb-3">
                          <Label>
                            Will auto-refresh broken URLs every 10 seconds
                          </Label>
                          <br></br>

                          <select
                            style={{
                              marginLeft: "0px",
                              width: "100%",
                              maxHeight: "50%",
                            }}
                            required
                            onChange={(e) => {
                              setProfileData((prevState) => ({
                                ...prevState,
                                enableAutoRefreshWebpage: e.target.value,
                              }));
                            }}
                            name="enableAutoRefreshWebpage"
                            className="custom-select"
                          >
                            <option defaultValue value="true">
                              True
                            </option>
                            <option value="false">False</option>
                          </select>
                        </div>

                        <div className="mb-3">
                          <Label>Auto-Refresh Time in Minutes</Label>
                          <br></br>

                          <select
                            style={{
                              marginLeft: "0px",
                              width: "100%",
                              maxHeight: "50%",
                            }}
                            required
                            onChange={(e) => {
                              setProfileData((prevState) => ({
                                ...prevState,
                                autorefreshTimeInMinutes: e.target.value,
                              }));
                            }}
                            name="refresh_time"
                            className="custom-select"
                          >
                            <option defaultValue value="15">
                              15
                            </option>
                            <option value="30">30</option>
                            <option value="45">45</option>
                            <option value="60">60</option>
                            <option value="75">75</option>
                            <option value="90">90</option>
                          </select>
                        </div>

                        <AvField
                          name="websiteURL"
                          label="Website Url"
                          placeholder="www.example.com"
                          type="text"
                          value={profileData.websiteURL}
                          onChange={handleChange}
                          errorMessage="Enter Url"
                        />
                      </>
                    ) : (
                      <AvField
                        name="loadFileWebviewPathName"
                        label="File name with address"
                        placeholder=" i.e storage/emulated/0/Download/myweb.html"
                        type="text"
                        value={profileData.loadFileWebviewPathName}
                        onChange={handleChange}
                        errorMessage="Enter Url"
                      />
                    )}
                  </div>
                </FormGroup>
              </>
            ) : (
              ""
            )}

            {profileData.mode == "app" ? (
              <>
                <FormGroup className="mb-0">
                  <div>
                    <Table className="table-centered table-vertical table-nowrap">
                      <tbody>
                        <tr>
                          <td>
                            <label>Reboot Device</label>
                          </td>
                          <td>
                            <input
                              onChange={(e) => {
                                if (e.target.checked == true) {
                                  setProfileData((prevState) => ({
                                    ...prevState,
                                    rebootDevice: "true",
                                  }));
                                } else {
                                  setProfileData((prevState) => ({
                                    ...prevState,
                                    rebootDevice: "false",
                                  }));
                                }
                              }}
                              name="rebootDevice"
                              type="checkbox"
                              className="m-0 form-control-sm ml-5"
                            />
                          </td>
                        </tr>

                        <tr>
                          <td>
                            <label>Refresh PlayScreen</label>
                          </td>
                          <td>
                            <input
                              onChange={(e) => {
                                if (e.target.checked == true) {
                                  setProfileData((prevState) => ({
                                    ...prevState,
                                    refreshScreen: "true",
                                  }));
                                } else {
                                  setProfileData((prevState) => ({
                                    ...prevState,
                                    refreshScreen: "false",
                                  }));
                                }
                              }}
                              name="refreshScreen"
                              type="checkbox"
                              className="m-0 form-control-sm ml-5"
                            />
                          </td>
                        </tr>
                      </tbody>
                    </Table>

                    <AvField
                      name="defaultApp"
                      label="Default App"
                      placeholder="Type Something"
                      type="text"
                      value={profileData.defaultApp}
                      onChange={handleChange}
                      errorMessage="Enter App Name"
                    />
                  </div>
                </FormGroup>
              </>
            ) : (
              ""
            )}

            {profileData?.mode == "web" ||
              profileData?.mode == "video" ||
              profileData?.mode == "app" ? (
              <div className="form-group">
                <label htmlFor="password">Password</label>
                <AvField
                  required
                  onChange={handleChange}
                  id="p1"
                  name="updateMlockPassword"
                  type="password"
                  value={profileData.updateMlockPassword || ""}
                  placeholder="Enter password"
                />
              </div>
            ) : (
              ""
            )}

            {hSetting == true ? (
              <>
                <div className="mb-3">
                  <Label>Allow Guest User</Label>
                  <br></br>

                  <select
                    style={{
                      marginLeft: "0px",
                      width: "100%",
                      maxHeight: "50%",
                    }}
                    required
                    onChange={(e) => {
                      setProfileData((prevState) => ({
                        ...prevState,
                        allowGuestUser: e.target.value,
                      }));
                    }}
                    name="allowGuestUser"
                    className="custom-select"
                  >
                    <option defaultValue value="true">
                      True
                    </option>
                    <option value="false">False</option>
                  </select>
                </div>

                <div className="form-group">
                  <label htmlFor="adminpassword">Admin Password</label>
                  <AvField
                    onChange={handleChange}
                    name="newPassword"
                    type="password"
                    value={profileData.newPassword}
                    placeholder="Enter Admin password"
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="guestPassword"> Guest Password</label>
                  <AvField
                    onChange={handleChange}
                    name="guestPassword"
                    type="password"
                    value={profileData.guestPassword}
                    placeholder="Enter Guest password"
                  />
                </div>
              </>
            ) : (
              ""
            )}

            {sSetting == true ? (
              <>
                <div className="mb-3">
                  <Label>Enable Scroll Text</Label>
                  <br></br>

                  <select
                    style={{
                      marginLeft: "0px",
                      width: "100%",
                      maxHeight: "50%",
                    }}
                    required
                    onChange={(e) => {
                      setProfileData((prevState) => ({
                        ...prevState,
                        enableScrollText: e.target.value,
                      }));
                    }}
                    name="enableScrollText"
                    className="custom-select"
                  >
                    <option defaultValue value="true">
                      True
                    </option>
                    <option value="false">False</option>
                  </select>
                </div>

                <AvField
                  name="scrollingText"
                  label="Scrolling Text"
                  placeholder="Type something"
                  type="text"
                  value={profileData.scrollingText}
                  onChange={handleChange}
                  errorMessage="Enter text"
                />

                <AvField
                  name="scrollingTextSpeed"
                  label="Scrolling Speed"
                  placeholder="Number between 5 - 100"
                  min={5}
                  onChange={handleChange}
                  value={profileData.scrollingTextSpeed}
                  type="number"
                  errorMessage="This value should be between 5 and 100."
                  validate={{
                    min: { value: 5 },
                    max: { value: 100 },
                  }}
                />
              </>
            ) : (
              ""
            )}

            {profileData.mode !== "exitMlock" && profileData.mode ? (
              <>
                <Button
                  onClick={() => {
                    setHSetting(true);
                    setSSetting(false);
                  }}
                  color="success"
                  className="waves-effect waves-light  mb-3 mr-1"
                  id="sa-title"
                >
                  Guest Setting
                </Button>

                <Button
                  onClick={() => {
                    setSSetting(true);
                    setHSetting(false);
                  }}
                  color="danger"
                  className="waves-effect waves-light mb-3 mr-1"
                  id="sa-title"
                >
                  Scrolling Text
                </Button>
              </>
            ) : (
              ""
            )}
          </>
        ) : (
          ""
        )}

        {profileData.profile_type == "Install User App" ? (
          <>
            <FormGroup>
              <div>
                <Label>Apk URL</Label>
                <br></br>
                <Select
                  value={selectedMulti}
                  isMulti={true}
                  onChange={handleMulti}
                  options={optionGroup}
                  className="select2 select2-multiple"
                  maxMenuHeight={100}
                />
              </div>
              {error && <p style={{ color: 'red' }}>Select Apk URL</p>}
            </FormGroup>
          </>
        ) : (
          ""
        )}

        {/* ===================== Update Mimo Service ===================== */}
        {profileData.profile_type === "updateMIMOService" ? (
          <div>
            {mState == true ? (
              <AvField
                name="apk_url"
                label=" Custom Apk Url"
                placeholder="Type URL"
                type="text"
                autoComplete="off"
                value={profileData.apk_url || ""}
                onChange={handleChange}
                errorMessage="Enter URL"
                validate={{ required: { value: true } }}
              />
            ) : (
              ""
            )}

            {mState == false ? (
              <FormGroup>
                <div>
                  <Label>Apk URL</Label>
                  <br></br>

                  <select
                    style={{
                      marginLeft: "0px",
                      width: "100%",
                      maxHeight: "50%",
                    }}
                    required
                    onChange={(e) => {
                      setProfileData((prevState) => ({
                        ...prevState,
                        apk_url: e.target.value,
                      }));
                    }}
                    name="apk_url"
                    value={profileData.apk_url || ""}
                    className="custom-select"
                  >
                    <option defaultValue value="">
                      Select Apk
                    </option>

                    {updateApkData.map((key, index) => {
                      return (
                        <option
                          key={index}
                          value={
                            process.env.REACT_APP_URL +
                            "/" +
                            key.app_name +
                            "/" +
                            key.file_name
                          }
                        >
                          {key.app_name + "/" + key.file_name}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </FormGroup>
            ) : (
              ""
            )}

            <Table className="table-centered table-vertical table-nowrap">
              <tbody>
                <tr>
                  <td>
                    <label>Customize Apk URL</label>
                  </td>
                  <td>
                    <input
                      onChange={(e) => {
                        if (e.target.checked == true) {
                          setMState(true);
                          setProfileData((prevState) => ({
                            ...prevState,
                            apk_url: "",
                          }));
                        } else {
                          setMState(false);
                          setProfileData((prevState) => ({
                            ...prevState,
                            apk_url: "",
                          }));
                        }
                      }}
                      name="custom_url"
                      type="checkbox"
                      className="m-0 form-control-sm ml-5"
                    />
                  </td>
                </tr>
              </tbody>
            </Table>
            <hr></hr>
          </div>
        ) : (
          ""
        )}

        {/* ===================== WiFi Profile ===================== */}
        {profileData.profile_type === "assignSSIDProfile" ? (
          <>
            <AvField
              name="ssid"
              label="SSID"
              placeholder="ExampleMobility"
              type="text"
              autoComplete="off"
              onChange={handleChange}
              errorMessage="Enter SSID"
              validate={{ required: { value: true } }}
            />

            <Label>Security Type</Label>
            <br></br>
            <select
              style={{
                marginLeft: "0px",
                width: "100%",
                maxHeight: "50%",
                marginBottom: "3%",
              }}
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Select Job"
              onChange={(e) => {
                setProfileData((prevState) => ({
                  ...prevState,
                  securityType: e.target.value,
                }));
              }}
              name="securityType"
              className="custom-select"
            >
              <option value="none">None</option>
              <option value="wep">WEP</option>
              <option value="wpa/wpa2">WPA/WPA2</option>
              <option value="wpaEnterprise">WPA/WPA2 Enterprise</option>
            </select>

            {profileData.securityType === "wpaEnterprise" ? (
              <AvField
                name="userId"
                label="User ID"
                placeholder="Enter user id"
                type="text"
                autoComplete="off"
                onChange={handleChange}
                errorMessage="Enter the user id"
                validate={{ required: { value: true } }}
              />
            ) : (
              ""
            )}

            <label>Password</label>
            <div class="input-group mb-3">
              <input
                className="form-control"
                name="password"
                placeholder="Enter the network password"
                type={passwordInputType}
                onChange={handleChange}
              />
              <div class="input-group-append">
                <span class="input-group-text">
                  {passwordEyeIcon ? (
                    <i
                      className="fa fa-eye-slash"
                      aria-hidden="true"
                      role="button"
                      onClick={() => {
                        handleShowPassword(false);
                      }}
                    ></i>
                  ) : (
                    <i
                      className="fa fa-eye"
                      aria-hidden="true"
                      role="button"
                      onClick={() => {
                        handleShowPassword(true);
                      }}
                    ></i>
                  )}
                </span>
              </div>
            </div>

            <Label>Hidden</Label>
            <br></br>
            <Switch
              uncheckedIcon={Offsymbol("No")}
              checkedIcon={OnSymbol("Yes")}
              onColor="#626ed4"
              onChange={(e) => {
                setProfileData((prevState) => ({
                  ...prevState,
                  hidden: !profileData.hidden,
                }));
              }}
              checked={profileData.hidden}
              className="mr-1"
            />
            <br></br>
            <br></br>

            <Label>Proxy Type</Label>
            <br></br>
            <select
              style={{
                marginLeft: "0px",
                width: "100%",
                maxHeight: "50%",
                marginBottom: "2%",
              }}
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Select Job"
              onChange={(e) => {
                setProfileData((prevState) => ({
                  ...prevState,
                  ip: "",
                  port: "",
                  proxyType: e.target.value,
                }));
              }}
              name="proxyType"
              className="custom-select"
            >
              <option value="none">None</option>
              <option value="automatic">Automatic</option>
              <option value="manual">Manual</option>
            </select>

            {profileData.proxyType === "manual" ? (
              <>
                <AvField
                  name="ip"
                  label="Ip"
                  placeholder="Enter ip"
                  type="text"
                  autoComplete="off"
                  onChange={handleChange}
                  errorMessage="Enter the ip"
                  validate={{ required: { value: true } }}
                />

                <AvField
                  name="port"
                  label="Port"
                  placeholder="Enter port"
                  type="text"
                  autoComplete="off"
                  onChange={handleChange}
                  errorMessage="Enter the port"
                  validate={{ required: { value: true } }}
                />
              </>
            ) : (
              ""
            )}
          </>
        ) : (
          ""
        )}

        <FormGroup className="mb-0">
          <Button
            type="submit"
            color="primary"
            className="waves-effect waves-light mr-1"
            id="sa-title"
          >
            Submit
          </Button>

          <Button
            type="reset"
            onClick={toggleModal}
            color="secondary"
            className="waves-effect"
            id="e1"
          >
            Cancel
          </Button>
        </FormGroup>
      </AvForm>
    </>
  );
};

export default CreateProfile;
