import React, { Component } from "react";
import { PieChart as Pies, Pie, Cell, Tooltip, Legend } from "recharts";
import "./pieChart.scss";

const COLORS = ["#3C4CCF", "#02A499", "#28bbe3", "#F4A261", "#8E44AD", "#F7DC6F", "#FF5733"];
const ANDROID_LABELS = ["Android 5", "Android 6", "Android 8", "Android 11", "Android 12", "Android 13", "Android 14"];

class PieChart extends Component {
  state = {
    modal_large: false,
    androidVersion: this.props.reports.androidVersion || [],
  };

  componentDidUpdate(prevProps) {
    if (prevProps.reports.androidVersion !== this.props.reports.androidVersion) {
      this.setState({ androidVersion: this.props.reports.androidVersion || [] });
    }
  }

  tog_large = () => {
    this.setState((prevState) => ({ modal_large: !prevState.modal_large }));
  };

  renderLegend = ({ payload }) => (
    <div className="legend-container">
      {payload?.map((entry, index) => (
        <div key={`legend-${index}`} className="legend-item">
          <div className="legend-box" style={{ backgroundColor: entry.color }} />
          <span>
            {entry.value} - <b>{this.state.androidVersion[index]}</b>
          </span>
        </div>
      ))}
    </div>
  );

  render() {
    const { androidVersion } = this.state;
    const androidData = androidVersion.map((value, index) => ({
      name: ANDROID_LABELS[index] || `Android ${index + 5}`,
      value,
    }));

    return (
      <Pies width={300} height={300}>
        <Pie data={androidData} cx="35%" cy="30%" outerRadius={60} fill="#8884d8" dataKey="value" label>
          {androidData.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
        <Tooltip />
        <Legend content={this.renderLegend} />
      </Pies>
    );
  }
}

export default PieChart;
