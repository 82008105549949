import React, { useState } from 'react';
import { Container, Row, Col, Card, CardBody, Button, Alert } from 'reactstrap';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { getCurrentUser } from '../../../services/authService';
import * as twoFactorApi from '../redux/twoFactorApi';
import { validate2FA } from '../../../store/actions';
import { Link, withRouter } from 'react-router-dom';
import SweetAlert from 'react-bootstrap-sweetalert';
import logo from '../../../assets/images/Mimo_Logo.png';
import { connect } from 'react-redux';

const CheckTwoFactor = (props) => {
  const [code, setCode] = useState('');
  const [successAlert, setSuccessAlert] = useState(false);
  const [description, setDescription] = useState('');

  const handleSubmit = (e, value) => {
    const { code } = value;
    props.validate2FA(code, props.history);
  };

  const handleResend = async () => {
    try {
      setSuccessAlert(true);
      setDescription('Code has been sent to your email.');

      await twoFactorApi.resendTwoFactor();
    } catch (ex) {
      console.error(ex);
    }
  };

  return (
    <>
      {successAlert ? (
        <SweetAlert
          success
          title='Success'
          onConfirm={() => setSuccessAlert(false)}
        >
          {description}
        </SweetAlert>
      ) : (
        ''
      )}

      <Container className='account-pages my-5 pt-sm-5'>
        <Row className='justify-content-center'>
          <Col md='8' lg='6' xl='5'>
            <Card className='overflow-hidden'>
              <CardBody className='pt-0'>
                <h3 className='text-center mt-4'>
                  <Link to='/' className='logo logo-admin'>
                    <img src={logo} height='80' alt='logo' />
                  </Link>
                </h3>
                <div className='p-3'>
                  <h4 className='text-muted font-size-18 mb-3 text-center'>
                    Two Factor Verification
                  </h4>

                  <h5 className='text-muted font-size-18 mb-3 text-center'>
                    We sent code to email:{' '}
                    {getCurrentUser().email.substr(0, 4) +
                      '******' +
                      getCurrentUser().email.substr(-2)}
                  </h5>

                  <AvForm
                    className='form-horizontal mt-4'
                    onValidSubmit={handleSubmit}
                  >
                    {props.apiError && (
                      <Alert color='danger'>{props.apiError}</Alert>
                    )}

                    <div className='form-group'>
                      <label>Code</label>
                      <AvField
                        name='code'
                        type='text'
                        id='code'
                        value={code}
                        placeholder='Enter Code'
                        validate={{
                          required: {
                            value: true,
                            errorMessage: 'Please enter a code',
                          },
                        }}
                      />
                    </div>

                    <div className='form-group row text-right'>
                      <div className='col-12'>
                        <Button
                          color='primary'
                          className='w-md waves-effect waves-light mr-2'
                          type='submit'
                        >
                          Verify
                        </Button>
                        <Button
                          color='danger'
                          className='w-md waves-effect waves-light'
                          href='/logout'
                        >
                          Logout
                        </Button>
                      </div>
                    </div>

                    <div className='form-group row text-left'>
                      <Col xs='12' className='mt-4'>
                        <a
                          className='text-primary'
                          role='button'
                          onClick={handleResend}
                        >
                          <i className='mdi mdi-refresh'></i> Resend code?
                        </a>
                      </Col>
                    </div>
                  </AvForm>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

const mapStatetoProps = (state) => {
  const { apiError } = state.TwoFactorAuth;
  return { apiError };
};

export default withRouter(
  connect(mapStatetoProps, { validate2FA })(CheckTwoFactor)
);
