export const REGISTER_USER = 'register_user';
export const REGISTER_USER_SUCCESSFUL = 'register_user_successfull';

export const CONFIRM_MAIL = 'confirm_mail';
export const CONFIRM_SUCCESS = 'confirm_success';
export const CONFIRM_ERROR = 'confirm_error';

export const API_FAILED = 'api_failed';
export const EMPTY_ERROR = 'empty_error';


