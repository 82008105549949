import React, { useEffect, useRef, useState } from "react";
import {
  Col,
  Row,
  Card,
  CardBody,
  FormGroup,
  Label,
  Button,
  Progress,
} from "reactstrap";
import { getCurrentUser } from "../../services/authService";
import { setBreadcrumbItems } from "../../store/actions";
import download from "../../assets/images/download.png";
import { AvForm } from "availity-reactstrap-validation";
import secureLocalStorage from "react-secure-storage";
import SweetAlert from "react-bootstrap-sweetalert";
import { connect } from "react-redux";
import Avatar from "react-avatar";
import axios from "axios";

const Profile = (props) => {
  const fileRef = useRef();
  const [breadcrumbItems] = useState([
    { title: "Mimo", link: "/" },
    { title: "Profile", link: "/profile" },
  ]);
  const [submitData, setSubmitData] = useState({
    file: null,
  });
  const [profileData, setProfileData] = useState({});
  const [description, setDescription] = useState("");
  const [successAlert, setSuccessAlert] = useState(false);
  const [errorAlert, setErrorAlert] = useState(false);
  const [loading, setLoading] = useState(null);
  const [lState, setLState] = useState(null);

  useEffect(() => {
    (async () => {
      props.setBreadcrumbItems("Profile", breadcrumbItems, true);

      const { REACT_APP_URL } = process.env;
      let data = secureLocalStorage.getItem("auth");
      let token = data.replace(/['"]+/g, "");

      axios
        .get(`${REACT_APP_URL}/api/auth/userShow/${getCurrentUser().id}`, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setProfileData(res.data);
        })
        .catch((error) => {
          console.error(error);
        }).finally(() => {
          props.setBreadcrumbItems("Profile", breadcrumbItems, false);
        });

    })();
  }, []);

  const handleChange = (e) => {
    const { name, value, files } = e.target;

    if (name === "file") {
      if (
        files[0].type !== "image/jpeg" &&
        files[0].type !== "image/png" &&
        files[0].type !== "image/jpg"
      ) {
        setErrorAlert(true);
        setDescription(
          "File format is incorrect (Please upload a jpg/jpeg/png File)"
        );
        setSubmitData((prevState) => ({
          ...prevState,
          file: null,
        }));
        fileRef.current.value = "";
      } else {
        setSubmitData((prevState) => ({
          ...prevState,
          [name]: files[0],
        }));
      }
    } else {
      setSubmitData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const handleSubmit = () => {
    const { REACT_APP_URL } = process.env;
    let data = secureLocalStorage.getItem("auth");
    let token = data.replace(/['"]+/g, "");

    if (!submitData.file) {
      setLState(false);
      setErrorAlert(true);
      setDescription("No file is uploaded");
    }

    const formData = new FormData();
    formData.append("file", submitData.file);
    formData.append("user_id", getCurrentUser().id);

    axios
      .post(`${REACT_APP_URL}/api/auth/uploadPic`, formData, {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
          "Content-Type":
            "multipart/form-data; charset=utf-8; boundary=" +
            Math.random().toString().substr(2),
        },
        onUploadProgress: (e) => {
          const { loaded, total } = e;
          let percent = Math.floor((loaded * 100) / total);
          setLState(false);
          setLoading(percent);
        },
      })
      .then((res) => {
        if (res.data.error) {
          setErrorAlert(true);
          setLState(false);
          setDescription(res.data.error);
        } else {
          setSuccessAlert(true);
          setDescription("Profile image successfully uploaded");
        }
      });
  };

  return (
    <>
      {successAlert ? (
        <SweetAlert
          title="Success"
          success
          onConfirm={() => {
            setSuccessAlert(false);
            props.history.replace("/dashboard");
          }}
        >
          {description}
        </SweetAlert>
      ) : null}
      {errorAlert ? (
        <SweetAlert
          title="Failed"
          error
          onConfirm={() => {
            setErrorAlert(false);
          }}
        >
          {description}
        </SweetAlert>
      ) : null}

      <Row>
        <Col lg="6">
          <Card>
            <CardBody>
              <Avatar
                size="200"
                className="rounded-circle header-profile-user"
                src={
                  profileData.picture
                    ? process.env.REACT_APP_URL + "/" + profileData.picture
                    : download
                }
              />

              <AvForm onValidSubmit={handleSubmit}>
                <FormGroup style={{ marginTop: "10px" }}>
                  <Label>Upload Image</Label>
                  <br></br>
                  <input
                    type="file"
                    name="file"
                    onChange={handleChange}
                    className="filestyle"
                    data-buttonname="btn-secondary"
                    ref={fileRef}
                  />
                </FormGroup>

                {lState ? (
                  <div className="">
                    <div className="mb-4">
                      <Progress color="primary" value={loading}>
                        {loading}
                      </Progress>
                    </div>
                  </div>
                ) : (
                  ""
                )}

                <FormGroup className="mb-0">
                  <Button
                    type="submit"
                    color="primary"
                    className="waves-effect waves-light mr-1"
                    id="sa-title"
                  >
                    Submit
                  </Button>

                  <Button
                    type="reset"
                    onClick={() => {
                      props.history.replace("/dashboard");
                    }}
                    color="secondary"
                    className="waves-effect"
                    id="e1"
                  >
                    Cancel
                  </Button>
                </FormGroup>
              </AvForm>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default connect(null, { setBreadcrumbItems })(Profile);
